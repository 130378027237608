import React, { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { db, storage } from "../utils/firebase"; // Adjust the import path as necessary
import {
  collection,
  addDoc,
  getDocs,
  getFirestore,
  query,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Swal from "sweetalert2";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  TextField,
  Typography,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  IconButton,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import imageCompression from "browser-image-compression";
interface Employee {
  id: string;
  firstName: string;
  role: string;
  // Add other fields as needed
}

const EODForm: React.FC = () => {
  const [shiftDate, setShiftDate] = useState<string>(
    localStorage.getItem("shiftDate") || new Date().toISOString().substr(0, 10)
  );
  const [shiftCloser, setShiftCloser] = useState<string>(
    localStorage.getItem("shiftCloser") || ""
  );
  const [startingFloat, setStartingFloat] = useState<string>(
    localStorage.getItem("startingFloat") || ""
  );
  const [totalCash, setTotalCash] = useState<string>(
    localStorage.getItem("totalCash") || ""
  );
  const [totalCards, setTotalCards] = useState<string>(
    localStorage.getItem("totalCards") || ""
  );
  const [cardReadingsPhoto, setCardReadingsPhoto] = useState<File | null>(null);
  const [, setCardReadingsPhotoURL] = useState<string>("");
  const [totalReceipts, setTotalReceipts] = useState<string>(
    localStorage.getItem("totalReceipts") || ""
  );
  const [receiptsPhoto, setReceiptsPhoto] = useState<File | null>(null);
  const [, setReceiptsPhotoURL] = useState<string>("");
  const [cancellationsAmount, setCancellationsAmount] = useState<string>(
    localStorage.getItem("cancellationsAmount") || ""
  );
  const [cancellationsPhoto, setCancellationsPhoto] = useState<File | null>(
    null
  );
  const [, setCancellationsPhotoURL] = useState<string>("");
  const [cancellationsReason, setCancellationsReason] = useState<string>(
    localStorage.getItem("cancellationsReason") || ""
  );
  const [zRead, setZRead] = useState<string>(
    localStorage.getItem("zRead") || ""
  );
  const [xRead, setXRead] = useState<string>(
    localStorage.getItem("xRead") || ""
  );
  const [discrepancy, setDiscrepancy] = useState<string>("");
  const [discrepancyReason, setDiscrepancyReason] = useState<string>(
    localStorage.getItem("discrepancyReason") || ""
  );
  const [endingFloat, setEndingFloat] = useState<string>(
    localStorage.getItem("endingFloat") || ""
  );

  const [xItemizedPhoto, setXItemizedPhoto] = useState<File | null>(null);
  const [, setXItemizedPhotoURL] = useState<string>("");
  const [zReadingPhoto, setZReadingPhoto] = useState<File | null>(null);
  const [, setZReadingPhotoURL] = useState<string>("");
  const [xReadingPhoto, setXReadingPhoto] = useState<File | null>(null);
  const [, setXReadingPhotoURL] = useState<string>("");
  const [shiftNotes, setShiftNotes] = useState<string>(
    localStorage.getItem("shiftNotes") || ""
  );
  const [invoicingEventAmount, setInvoicingEventAmount] = useState<string>(
    localStorage.getItem("invoicingEventAmount") || ""
  );
  const [nonPhysicalBillPhoto, setNonPhysicalBillPhoto] =
    useState<FileList | null>(null);
  const [, setNonPhysicalBillPhotoURLs] = useState<string[]>([]);
  const [moneyLent, setMoneyLent] = useState<string>(
    localStorage.getItem("moneyLent") || ""
  );
  const [moneyLentReason, setMoneyLentReason] = useState<string>(
    localStorage.getItem("moneyLentReason") || ""
  );
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [drinksCount, setDrinksCount] = useState<string>(
    localStorage.getItem("drinksCount") || ""
  );
  const [drinksValue, setDrinksValue] = useState<string>(
    localStorage.getItem("drinksValue") || ""
  );
  const [pizzasCount, setPizzasCount] = useState<string>(
    localStorage.getItem("pizzasCount") || ""
  );
  const [pizzasValue, setPizzasValue] = useState<string>(
    localStorage.getItem("pizzasValue") || ""
  );
  const [startersCount, setStartersCount] = useState<string>(
    localStorage.getItem("startersCount") || ""
  );
  const [startersValue, setStartersValue] = useState<string>(
    localStorage.getItem("startersValue") || ""
  );
  const [dessertsCount, setDessertsCount] = useState<string>(
    localStorage.getItem("dessertsCount") || ""
  );
  const [dessertsValue, setDessertsValue] = useState<string>(
    localStorage.getItem("dessertsValue") || ""
  );

  const [shiftClosers, setShiftClosers] = useState<string[]>([]);
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserEmail(user.email);
      } else {
        setUserEmail(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    localStorage.setItem("shiftDate", shiftDate);
    localStorage.setItem("shiftCloser", shiftCloser);
    localStorage.setItem("startingFloat", startingFloat);
    localStorage.setItem("totalCash", totalCash);
    localStorage.setItem("totalCards", totalCards);
    localStorage.setItem("totalReceipts", totalReceipts);
    localStorage.setItem("cancellationsAmount", cancellationsAmount);
    localStorage.setItem("cancellationsReason", cancellationsReason);
    localStorage.setItem("zRead", zRead);
    localStorage.setItem("xRead", xRead);
    localStorage.setItem("discrepancyReason", discrepancyReason);
    localStorage.setItem("endingFloat", endingFloat);
    localStorage.setItem("shiftNotes", shiftNotes);
    localStorage.setItem("invoicingEventAmount", invoicingEventAmount);
    localStorage.setItem("moneyLent", moneyLent);
    localStorage.setItem("moneyLentReason", moneyLentReason);
    localStorage.setItem("drinksCount", drinksCount);
    localStorage.setItem("drinksValue", drinksValue);
    localStorage.setItem("pizzasCount", pizzasCount);
    localStorage.setItem("pizzasValue", pizzasValue);
    localStorage.setItem("startersCount", startersCount);
    localStorage.setItem("startersValue", startersValue);
    localStorage.setItem("dessertsCount", dessertsCount);
    localStorage.setItem("dessertsValue", dessertsValue);
  }, [
    shiftDate,
    shiftCloser,
    startingFloat,
    totalCash,
    totalCards,
    totalReceipts,
    cancellationsAmount,
    cancellationsReason,
    zRead,
    xRead,
    discrepancyReason,
    endingFloat,
    shiftNotes,
    invoicingEventAmount,
    moneyLent,
    moneyLentReason,
    drinksCount,
    drinksValue,
    pizzasCount,
    pizzasValue,
    startersCount,
    startersValue,
    dessertsCount,
    dessertsValue,
  ]);

  useEffect(() => {
    const calculateDiscrepancy = () => {
      const zReadValue = parseFloat(zRead) || 0;
      // const xReadValue = parseFloat(xRead) || 0;
      const totalCashValue = parseFloat(totalCash) || 0;
      const totalCardsValue = parseFloat(totalCards) || 0;
      const startingFloatValue = parseFloat(startingFloat) || 0;
      const endingFloatValue = parseFloat(endingFloat) || 0;
      const totalReceiptsValue = parseFloat(totalReceipts) || 0;
      const moneyLentValue = parseFloat(moneyLent) || 0;
      const calculatedDiscrepancy =
        zReadValue -
        (totalCashValue +
          totalCardsValue +
          totalReceiptsValue +
          moneyLentValue) -
        startingFloatValue +
        endingFloatValue;
      setDiscrepancy(calculatedDiscrepancy.toFixed(2));
    };

    calculateDiscrepancy();
  }, [
    zRead,
    // xRead,
    totalCash,
    totalCards,
    startingFloat,
    endingFloat,
    totalReceipts,
    moneyLent,
  ]);
  useEffect(() => {
    fetchEmployees();
    console.log("Fetching employees...", shiftClosers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchEmployees = async () => {
    const db = getFirestore();
    const usersQuery = query(collection(db, "users"));
    const querySnapshot = await getDocs(usersQuery);
    const employeesList: any[] = [];
    const closers: string[] = [];

    querySnapshot.forEach((doc) => {
      const employee = { id: doc.id, ...doc.data() } as Employee;
      employeesList.push(employee);
      if (
        ["admin", "manager", "shiftleader"].includes(
          employee.role.toLowerCase()
        ) &&
        employee.firstName !== "Paul"
      ) {
        closers.push(employee.firstName);
      }
    });

    setShiftClosers(closers);
    console.log(employeesList);
  };
  const handleFileUpload = async (file: File, folder: string) => {
    try {
      console.log("Received file: ", file);

      if (!(file instanceof File)) {
        throw new Error("Invalid file input");
      }

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
        maxIteration: 10,
      };

      const compressedFile = await imageCompression(file, options);
      console.log("Compressed file: ", compressedFile);

      const fileRef = ref(storage, `${folder}/${compressedFile.name}`);
      await uploadBytes(fileRef, compressedFile);

      const downloadURL = await getDownloadURL(fileRef);
      console.log("File uploaded successfully. Download URL:", downloadURL);

      return downloadURL;
    } catch (error) {
      console.error("Error during file compression/upload:", error);
      throw error;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const fields = [
      { name: "userEmail", value: userEmail },
      { name: "shiftDate", value: shiftDate },
      { name: "shiftCloser", value: shiftCloser },
      { name: "startingFloat", value: startingFloat },
      { name: "totalCash", value: totalCash },
      { name: "totalCards", value: totalCards },
      { name: "cardReadingsPhoto", value: cardReadingsPhoto },
      { name: "zRead", value: zRead },
      { name: "xRead", value: xRead },
      { name: "discrepancy", value: discrepancy },
      { name: "endingFloat", value: endingFloat },
      { name: "xItemizedPhoto", value: xItemizedPhoto },
      { name: "zReadingPhoto", value: zReadingPhoto },
      { name: "xReadingPhoto", value: xReadingPhoto },
      { name: "drinksCount", value: drinksCount },
      { name: "drinksValue", value: drinksValue },
      { name: "pizzasCount", value: pizzasCount },
      { name: "pizzasValue", value: pizzasValue },
      { name: "startersCount", value: startersCount },
      { name: "startersValue", value: startersValue },
      { name: "dessertsCount", value: dessertsCount },
      { name: "dessertsValue", value: dessertsValue },
    ];

    const emptyFields = fields
      .filter((field) => !field.value)
      .map((field) => field.name)
      .join(", ");

    if (emptyFields.length > 0) {
      Swal.fire(
        "Error",
        `The following fields are empty: ${emptyFields}. Please fill all required fields and ensure all photos are uploaded.`,
        "error"
      );
      return;
    }

    Swal.fire({
      title: "Submitting...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const formData: any = {
      shiftDate,
      shiftCloser,
      startingFloat,
      totalCash,
      totalCards,
      totalReceipts,
      cancellationsAmount,
      cancellationsReason,
      zRead,
      xRead,
      discrepancy,
      discrepancyReason,
      endingFloat,
      shiftNotes,
      invoicingEventAmount,
      moneyLent,
      moneyLentReason,
      drinksCount,
      drinksValue,
      pizzasCount,
      pizzasValue,
      startersCount,
      startersValue,
      dessertsCount,
      dessertsValue,
      userEmail,
      timestamp: new Date(),
    };

    try {
      if (cardReadingsPhoto) {
        formData.cardReadingsPhotoURL = await handleFileUpload(
          cardReadingsPhoto,
          "cardReadings"
        );
      }
      if (receiptsPhoto) {
        formData.receiptsPhotoURL = await handleFileUpload(
          receiptsPhoto,
          "receipts"
        );
      }
      if (cancellationsPhoto) {
        formData.cancellationsPhotoURL = await handleFileUpload(
          cancellationsPhoto,
          "cancellations"
        );
      }
      if (xItemizedPhoto) {
        formData.xItemizedPhotoURL = await handleFileUpload(
          xItemizedPhoto,
          "xItemized"
        );
      }
      if (zReadingPhoto) {
        formData.zReadingPhotoURL = await handleFileUpload(
          zReadingPhoto,
          "zReading"
        );
      }
      if (xReadingPhoto) {
        formData.xReadingPhotoURL = await handleFileUpload(
          xReadingPhoto,
          "xReading"
        );
      }
      if (nonPhysicalBillPhoto) {
        const urls = [];
        for (const file of Array.from(nonPhysicalBillPhoto)) {
          const url = await handleFileUpload(file, "nonPhysicalBill");
          urls.push(url);
        }
        formData.nonPhysicalBillPhotoURLs = urls;
      }

      const docRef = await addDoc(collection(db, "eod_reports"), formData);
      console.log("Document written with ID: ", docRef.id);
      Swal.close();
      Swal.fire(
        "Success",
        "EOD report has been submitted successfully!",
        "success"
      );

      // Clear all inputs and localStorage
      setShiftDate(new Date().toISOString().substr(0, 10));
      setShiftCloser("");
      setStartingFloat("");
      setTotalCash("");
      setTotalCards("");
      setCardReadingsPhoto(null);
      setCardReadingsPhotoURL("");
      setTotalReceipts("");
      setReceiptsPhoto(null);
      setReceiptsPhotoURL("");
      setCancellationsAmount("");
      setCancellationsPhoto(null);
      setCancellationsPhotoURL("");
      setCancellationsReason("");
      setZRead("");
      setXRead("");
      setDiscrepancy("");
      setDiscrepancyReason("");
      setEndingFloat("");
      setXItemizedPhoto(null);
      setXItemizedPhotoURL("");
      setZReadingPhoto(null);
      setZReadingPhotoURL("");
      setXReadingPhoto(null);
      setXReadingPhotoURL("");
      setShiftNotes("");
      setInvoicingEventAmount("");
      setNonPhysicalBillPhoto(null);
      setNonPhysicalBillPhotoURLs([]);
      setMoneyLent("");
      setMoneyLentReason("");
      setDrinksCount("");
      setDrinksValue("");
      setPizzasCount("");
      setPizzasValue("");
      setStartersCount("");
      setStartersValue("");
      setDessertsCount("");
      setDessertsValue("");

      localStorage.clear();
    } catch (e) {
      console.error("Error adding document: ", e);
      Swal.fire(
        "Error",
        "There was an error submitting the EOD report. Please try again.",
        "error"
      );
    } finally {
    }
  };

  const handleFileRemove = (fileType: string) => {
    switch (fileType) {
      case "cardReadings":
        setCardReadingsPhoto(null);
        setCardReadingsPhotoURL("");
        break;
      case "receipts":
        setReceiptsPhoto(null);
        setReceiptsPhotoURL("");
        break;
      case "cancellations":
        setCancellationsPhoto(null);
        setCancellationsPhotoURL("");
        break;
      case "xItemized":
        setXItemizedPhoto(null);
        setXItemizedPhotoURL("");
        break;
      case "zReading":
        setZReadingPhoto(null);
        setZReadingPhotoURL("");
        break;
      case "xReading":
        setXReadingPhoto(null);
        setXReadingPhotoURL("");
        break;
      case "nonPhysicalBill":
        setNonPhysicalBillPhoto(null);
        setNonPhysicalBillPhotoURLs([]);
        break;
      default:
        break;
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Salumeria Gardens EOD Form
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="shiftDate"
                label="Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={shiftDate}
                onChange={(e) => setShiftDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel id="shiftCloser-label">Shift Closer</InputLabel>
                <Select
                  labelId="shiftCloser-label"
                  id="shiftCloser"
                  value={shiftCloser}
                  label="Shift Closer"
                  onChange={(e) => setShiftCloser(e.target.value)}
                >
                  {shiftClosers.map((closer) => (
                    <MenuItem key={closer} value={closer}>
                      {closer}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="startingFloat"
                label="Starting Float"
                type="number"
                value={startingFloat}
                onChange={(e) => setStartingFloat(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                id="totalCash"
                label="Total Cash"
                type="number"
                value={totalCash}
                onChange={(e) => setTotalCash(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                id="totalCards"
                label="Total Cards"
                type="number"
                value={totalCards}
                onChange={(e) => setTotalCards(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel id="cardReadingsPhoto-label">
                Photo of Card readings
              </InputLabel>
              <Button variant="contained" component="label" fullWidth>
                Upload
                <input
                  type="file"
                  hidden
                  onChange={(e) =>
                    setCardReadingsPhoto(e.target.files?.[0] || null)
                  }
                />
              </Button>
              {cardReadingsPhoto && (
                <Box sx={{ mt: 2 }}>
                  <Typography>Uploaded: {cardReadingsPhoto.name}</Typography>
                  <IconButton
                    color="secondary"
                    onClick={() => handleFileRemove("cardReadings")}
                  >
                    <Delete />
                  </IconButton>
                </Box>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="totalReceipts"
                label="Total of any receipts from Cash"
                type="number"
                value={totalReceipts}
                onChange={(e) => setTotalReceipts(e.target.value)}
              />
            </Grid>
            {parseFloat(totalReceipts) > 0 && (
              <Grid item xs={12}>
                <InputLabel id="receiptsPhoto-label">
                  Photo of receipts (all in 1 photo)
                </InputLabel>
                <Button variant="contained" component="label" fullWidth>
                  Upload
                  <input
                    type="file"
                    hidden
                    onChange={(e) =>
                      setReceiptsPhoto(e.target.files?.[0] || null)
                    }
                  />
                </Button>
                {receiptsPhoto && (
                  <Box sx={{ mt: 2 }}>
                    <Typography>Uploaded: {receiptsPhoto.name}</Typography>
                    <IconButton
                      color="secondary"
                      onClick={() => handleFileRemove("receipts")}
                    >
                      <Delete />
                    </IconButton>
                  </Box>
                )}
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="moneyLent"
                label="Money lent from cash"
                type="number"
                value={moneyLent}
                onChange={(e) => setMoneyLent(e.target.value)}
              />
            </Grid>
            {parseFloat(moneyLent) > 0 && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="moneyLentReason"
                  label="Reason and who lent"
                  multiline
                  rows={3}
                  value={moneyLentReason}
                  onChange={(e) => setMoneyLentReason(e.target.value)}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="zRead"
                label="Z read"
                type="number"
                value={zRead}
                onChange={(e) => setZRead(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="xRead"
                label="X reading at 5:00"
                type="number"
                value={xRead}
                onChange={(e) => setXRead(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="discrepancy"
                label="Discrepancy (calculated)"
                type="number"
                value={discrepancy}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            {parseFloat(discrepancy) !== 0 && (
              <>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="discrepancyReason"
                    label="Reason for Discrepancy"
                    multiline
                    rows={3}
                    value={discrepancyReason}
                    onChange={(e) => setDiscrepancyReason(e.target.value)}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="endingFloat"
                label="Ending Float"
                type="number"
                value={endingFloat}
                onChange={(e) => setEndingFloat(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                id="drinksCount"
                label="Total number of drinks"
                type="tel"
                value={drinksCount}
                onChange={(e) => setDrinksCount(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                id="drinksValue"
                label="Total value of drinks"
                type="number"
                value={drinksValue}
                onChange={(e) => setDrinksValue(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                id="pizzasCount"
                label="Total number of pizzas"
                type="tel"
                value={pizzasCount}
                onChange={(e) => setPizzasCount(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                id="pizzasValue"
                label="Total value of pizzas"
                type="number"
                value={pizzasValue}
                onChange={(e) => setPizzasValue(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                id="startersCount"
                label="Total number of starters"
                type="tel"
                value={startersCount}
                onChange={(e) => setStartersCount(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                id="startersValue"
                label="Total value of starters"
                type="number"
                value={startersValue}
                onChange={(e) => setStartersValue(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                id="dessertsCount"
                label="Total number of desserts"
                type="tel"
                value={dessertsCount}
                onChange={(e) => setDessertsCount(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                id="dessertsValue"
                label="Total value of desserts"
                type="number"
                value={dessertsValue}
                onChange={(e) => setDessertsValue(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel id="xItemizedPhoto-label">
                Photo of X itemized
              </InputLabel>
              <Button variant="contained" component="label" fullWidth>
                Upload
                <input
                  type="file"
                  hidden
                  onChange={(e) =>
                    setXItemizedPhoto(e.target.files?.[0] || null)
                  }
                />
              </Button>
              {xItemizedPhoto && (
                <Box sx={{ mt: 2 }}>
                  <Typography>Uploaded: {xItemizedPhoto.name}</Typography>
                  <IconButton
                    color="secondary"
                    onClick={() => handleFileRemove("xItemized")}
                  >
                    <Delete />
                  </IconButton>
                </Box>
              )}
            </Grid>
            <Grid item xs={12}>
              <InputLabel id="zReadingPhoto-label">
                Photo of Z reading
              </InputLabel>
              <Button variant="contained" component="label" fullWidth>
                Upload
                <input
                  type="file"
                  hidden
                  onChange={(e) =>
                    setZReadingPhoto(e.target.files?.[0] || null)
                  }
                />
              </Button>
              {zReadingPhoto && (
                <Box sx={{ mt: 2 }}>
                  <Typography>Uploaded: {zReadingPhoto.name}</Typography>
                  <IconButton
                    color="secondary"
                    onClick={() => handleFileRemove("zReading")}
                  >
                    <Delete />
                  </IconButton>
                </Box>
              )}
            </Grid>
            <Grid item xs={12}>
              <InputLabel id="xReadingPhoto-label">
                Photo of X reading at 5:00
              </InputLabel>
              <Button variant="contained" component="label" fullWidth>
                Upload
                <input
                  type="file"
                  hidden
                  onChange={(e) =>
                    setXReadingPhoto(e.target.files?.[0] || null)
                  }
                />
              </Button>
              {xReadingPhoto && (
                <Box sx={{ mt: 2 }}>
                  <Typography>Uploaded: {xReadingPhoto.name}</Typography>
                  <IconButton
                    color="secondary"
                    onClick={() => handleFileRemove("xReading")}
                  >
                    <Delete />
                  </IconButton>
                </Box>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="cancellationsAmount"
                label="Amount of Cancellations"
                type="tel"
                value={cancellationsAmount}
                onChange={(e) => setCancellationsAmount(e.target.value)}
              />
            </Grid>
            {parseFloat(cancellationsAmount) > 0 && (
              <>
                <Grid item xs={12}>
                  <InputLabel id="cancellationsPhoto-label">
                    Photo of Cancellations (all in 1 photo)
                  </InputLabel>
                  <Button variant="contained" component="label" fullWidth>
                    Upload
                    <input
                      type="file"
                      hidden
                      onChange={(e) =>
                        setCancellationsPhoto(e.target.files?.[0] || null)
                      }
                    />
                  </Button>
                  {cancellationsPhoto && (
                    <Box sx={{ mt: 2 }}>
                      <Typography>
                        Uploaded: {cancellationsPhoto.name}
                      </Typography>
                      <IconButton
                        color="secondary"
                        onClick={() => handleFileRemove("cancellations")}
                      >
                        <Delete />
                      </IconButton>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="cancellationsReason"
                    label="Reason for Cancellations"
                    multiline
                    rows={3}
                    value={cancellationsReason}
                    onChange={(e) => setCancellationsReason(e.target.value)}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="shiftNotes"
                label="Anything important to mention about the shift?"
                multiline
                rows={3}
                value={shiftNotes}
                onChange={(e) => setShiftNotes(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="invoicingEventAmount"
                label="Invoicing Event - Amount Spent"
                type="number"
                value={invoicingEventAmount}
                onChange={(e) => setInvoicingEventAmount(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel id="nonPhysicalBillPhoto-label">
                Photo of non-physical bill
              </InputLabel>
              <Button variant="contained" component="label" fullWidth>
                Upload
                <input
                  type="file"
                  hidden
                  multiple
                  onChange={(e) => setNonPhysicalBillPhoto(e.target.files)}
                />
              </Button>
              {nonPhysicalBillPhoto && (
                <Box sx={{ mt: 2 }}>
                  <Typography>
                    Uploaded: {nonPhysicalBillPhoto.length} files
                  </Typography>
                  <IconButton
                    color="secondary"
                    onClick={() => handleFileRemove("nonPhysicalBill")}
                  >
                    <Delete />
                  </IconButton>
                </Box>
              )}
            </Grid>
          </Grid>
          <button
            type="submit"
            className="bg-salumeria hover:bg-salumeria-light text-white font-bold py-2 px-4 rounded mt-4 mb-4 mx-auto block"
          >
            Submit
          </button>
        </Box>
      </Box>
    </Container>
  );
};

export default EODForm;
